$COMPANY: "ewf";
            $NODE_ENV: "production";
            $secondary: #fcba5f;;
            $slight   : #ffec8f;;
            $sdark    : #c68a30;;
            $stext    : #2f2f2f;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$PrivateLayoutStyles-menu_header_size      : 40px;
$PrivateLayoutStyles-menu_burger_size      : 40px;
$PrivateLayoutStyles-header_height_desktop : 82px + ($GlobalStyles-standard_space*4);
$PrivateLayoutStyles-header_height_mobile  : $PrivateLayoutStyles-menu_burger_size + ($GlobalStyles-standard_space*2);

.header {
	position   : absolute;
	left       : 0px;
	top        : 0px;
	width      : 100%;
	z-index    : 4;
}

.header_boundary {
	@include GlobalStyles-clearfix;
	position: relative;
}

.logo_container {
	position : absolute;
	left     : 0px;
	width    : 100%;
	height   : 100%;
}

.logo {
	vertical-align: middle;
}

.mobile_menu_burger {
	position: relative;
	display : inline-block;
	float   : right;

	.menu_burger_button {
		line-height: 0px;
		padding    : 0px;

		button {
			min-width  : 0px;
		}
	}

	:global(.GlobalStyles-button) {
		padding    : 0px;
		height     : $PrivateLayoutStyles-menu_burger_size;
		line-height: $PrivateLayoutStyles-menu_burger_size;
		width      : $PrivateLayoutStyles-menu_burger_size;
		text-align : center; 
	}
}

.menu_clickable_background {
	position: absolute;
	left    : 0px;
	top     : 0px;
	height  : 100%;
	width   : 100%;
}

.mobile_menu_header {
	@include GlobalStyles-clearfix;
	border-bottom: 1px solid darken($ColorStyles-plight, 5%);
	font-weight  : bold; 
	color        : darken($ColorStyles-pdark, 40%);
	height       : $PrivateLayoutStyles-menu_header_size; 
	line-height  : $PrivateLayoutStyles-menu_header_size; 

	.menu_header_text {
		display: inline-block;
		padding: 0px $GlobalStyles-standard_space * 2;
	}

	.menu_hide_button {
		position        : relative;
		display         : inline-block;
		float           : right;
		padding         : 0px;
		width           : $PrivateLayoutStyles-menu_header_size;
		height          : $PrivateLayoutStyles-menu_header_size;
		line-height     : $PrivateLayoutStyles-menu_header_size + 4px;
		text-align      : center;
		font-size       : $GlobalStyles-standard_font + 8px;
		background-color: transparent;
		border          : 0px;
		color           : $ColorStyles-secondary;
	}
}

.menu {
	position: relative;
	display : inline-block;
	float   : right;

	a {
		position       : relative;
		display        : inline-block;
		line-height    : normal;
		text-decoration: none !important;
	}

	button {
		position        : relative;
		display         : inline-block;
		line-height     : normal;
		border          : 0px;
		padding         : 0px;
		background-color: transparent;
		color           : $ColorStyles-secondary;

		&:hover {
			color     : $ColorStyles-slight;
			transition: all 0.2s;
		}
	}

	:global(.Routes-home .Routes-link_text), .logout_text {
		@include GlobalStyles-button_structure;
		display: block;

		&:hover,&:active,&:focus {
			background: lighten($ColorStyles-primary,6%);
		}
	}

	:global(.Routes-realaccount) {
		@include GlobalStyles-button;
	}
}

.body {
	position: relative;
	height  : 100%;
}

@media all and (min-width: $GlobalStyles-screen-desktop) {

	.mobile_menu_burger,.mobile_menu_header, .menu_clickable_background {
		display: none;
	}

	.header {
		height     : $PrivateLayoutStyles-header_height_desktop;
		line-height: $PrivateLayoutStyles-header_height_desktop;
	}

	.body {
		padding-top: $PrivateLayoutStyles-header_height_desktop;
	}

	.menu {


		a {
			position   : relative;
			display    : inline-block;
			line-height: normal;

			+ a {
				padding-left: $GlobalStyles-standard_space;
				margin-left : $GlobalStyles-standard_space;

				&::before {
					@include ColorStyles-sdark-v-fading-edges;
					@include GlobalStyles-pseudo;
					position   : absolute;
					top        : $GlobalStyles-standard_space/2;
					bottom     : $GlobalStyles-standard_space/2;
					left       : 0px;
					width      : 1px;
				}

				&:global(.Routes-realaccount) {
					margin-right: $GlobalStyles-standard_space;
				}
			}

			+ button {
				padding-left: $GlobalStyles-standard_space;
				margin-left : $GlobalStyles-standard_space;

				&::before {
					@include ColorStyles-sdark-v-fading-edges;
					@include GlobalStyles-pseudo;
					top   : $GlobalStyles-standard_space/2;
					bottom: $GlobalStyles-standard_space/2;
					left  : 0px;
					width : 1px;
				}

				&:global(.Routes-realaccount) {
					margin-right: $GlobalStyles-standard_space;
				}
			}
		}

	}
	
	.logo_container {
		padding-right: 507px + ($GlobalStyles-standard_space*2); 
	}
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
	.mobile_menu_burger,.mobile_menu_header, .menu_clickable_background {
		display: block;
	}

	.header {
		height     : $PrivateLayoutStyles-header_height_mobile;
		line-height: $PrivateLayoutStyles-header_height_mobile;
	}

	.body {
		padding-top: $PrivateLayoutStyles-header_height_mobile;
	}

	.logo_container {
		padding-right: $PrivateLayoutStyles-menu_header_size + ($GlobalStyles-standard_space*3);

		:global(.ImageStyles-image_size) {
			max-height: $PrivateLayoutStyles-header_height_mobile - ($GlobalStyles-standard_space*2);
		}
	}

	.menu {
		@include ColorStyles-sdark(.9);
		position  : fixed;
		height    : 100%;
		width     : 100%;
		right     : -100%;
		top       : 0px;
		z-index   : -1; 
		opacity   : 0;
		transition: all $GlobalStyles-transition, 
					z-index   $GlobalStyles-transition ease $GlobalStyles-transition, 
					transform $GlobalStyles-transition ease $GlobalStyles-transition;
	    transform : translate3d(0%,0,0);

		&.active {
			transition: all $GlobalStyles-transition, z-index 0s, transform 0s;
			z-index  : 5;
			opacity  : 1;
	    	transform: translate3d(-100%,0,0);

			.menu_content {
				transition : all $GlobalStyles-transition ease $GlobalStyles-transition/2;
			    opacity    : 1;
	    		transform  : translate3d(0%,0,0);
			}
		}

		a {
			display: block;

			&:global(.Routes-home), &.logout {
				border-top: 1px solid darken($ColorStyles-plight, 5%);
			}

			&.logout {
				border-bottom: 1px solid darken($ColorStyles-plight, 5%);
			}

			&:global(.Routes-home .Routes-link_text), .logout_text {
				text-align: left;
				padding   : 0px $GlobalStyles-standard_space*3;
			}

			&:global(.Routes-realaccount) {
				width : auto;
				margin: $GlobalStyles-standard_space;
			}
		}

		button {
			display: block;
			width  : 100%;

			&:global(.Routes-home), &.logout {
				border-top: 1px solid darken($ColorStyles-plight, 5%);
			}

			&.logout {
				border-bottom: 1px solid darken($ColorStyles-plight, 5%);
			}

			&:global(.Routes-home .Routes-link_text), .logout_text {
				text-align: left;
				padding   : 0px $GlobalStyles-standard_space*3;
			}

			&:global(.Routes-realaccount) {
				width : auto;
				margin: $GlobalStyles-standard_space;
			}
		}

		.menu_content {
			@include ColorStyles-plight;
			transition : all $GlobalStyles-transition;
    		box-shadow : -5px 0px 15px rgba(darken($ColorStyles-pdark,100%), 0.2);
			line-height: normal;
			position   : relative;
			width      : auto;
			float      : right;
			height     : 100%;
			max-width  : 100%; 
			min-width  : 236px; 
		    opacity    : 0;
    		transform  : translate3d(50%,0,0);
    		z-index    : 2;
		}
	}

}