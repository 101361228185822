$COMPANY: "ewf";
            $NODE_ENV: "production";
            $secondary: #fcba5f;;
            $slight   : #ffec8f;;
            $sdark    : #c68a30;;
            $stext    : #2f2f2f;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

.activate_button_wrapper {
	height         : 100vh;
	display        : flex;
	justify-content: center;
	align-items    : center;
	font-size      : $GlobalStyles-standard_font + 8px;
}

// 699px
@media all and (max-width: $GlobalStyles-screen-mobile - 1) {
	.activate_button_wrapper {
		font-size: $GlobalStyles-standard_font - 2px;
	}
}

// 399px
@media all and (max-width: ($GlobalStyles-screen-mobile - $GlobalStyles-min_width) + 19) {
	.activate_button_wrapper {
		font-size: $GlobalStyles-standard_font - 3px;
	}
}

// 379px
@media all and (max-width: ($GlobalStyles-screen-mobile - $GlobalStyles-min_width) - 1) {
	.activate_button_wrapper {
		font-size: $GlobalStyles-standard_font - 4px;
	}
}

// 359px
@media all and (max-width: ($GlobalStyles-screen-mobile - $GlobalStyles-min_width) - 21) {
	.activate_button_wrapper {
		font-size: $GlobalStyles-standard_font - 5px;
	}
}

// 339px
@media all and (max-width: $GlobalStyles-min_width + 19) {
	.activate_button_wrapper {
		font-size: $GlobalStyles-standard_font - 6px;
	}
}