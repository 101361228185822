$COMPANY: "ewf";
            $NODE_ENV: "production";
            $secondary: #fcba5f;;
            $slight   : #ffec8f;;
            $sdark    : #c68a30;;
            $stext    : #2f2f2f;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

:global(body.PrivateLayoutStyles-menu_open) {
	overflow: hidden;
}

:export { mobile_width:  $GlobalStyles-screen-desktop - 1px };