$COMPANY: "ewf";
            $NODE_ENV: "production";
            $secondary: #fcba5f;;
            $slight   : #ffec8f;;
            $sdark    : #c68a30;;
            $stext    : #2f2f2f;;
            $ssuccess : #3fc54a;;
            $sblue    : #005aa0;;
            $serror   : red;;
            
@import '~app/scss/global/GlobalStyles.module';

$Step8Styles-max_width   : 438px;
$Step8Styles-row_size    : 90px;
$Step8Styles-row_padding : $GlobalStyles-standard_space*2;
$Step8Styles-body_top    : $GlobalStyles-standard_space * 5;

.wrapper {
    @include GlobalStyles-child-center('.v_center_container');
    height    : 100%;
    padding   : $GlobalStyles-standard_space*2 0px;
    min-height: 300px;
}

.background_filler {
    @include ColorStyles-sdark;
    position  : fixed;
    top       : 50%;
    transform : translateY(78px);
    bottom    : 0px;
    width     : 100%;
}

.v_center_container {
    @include GlobalStyles-slant_container_top($Step8Styles-body_top);
    width     : 100%;
    border-top: 0px !important;
}

.content_boundary {
    width     : 100%;
    text-align: center;
}

.body_content_wrapper {
    position  : relative;
    text-align: left; 
    width     : 100%;
    max-width : $Step8Styles-max_width !important;
    display   : inline-block;
    z-index   : 2; 
    min-height: 350px;
}

.thank_you {
    text-align: center;
    padding   : $GlobalStyles-standard_space;
    color     : rgba($ColorStyles-ptext, .5);
}

.deposit_to_account, .send_deposit_proof {
    @include ColorStyles-p(.3);
    padding       : $GlobalStyles-standard_space;
    text-align    : center;
    text-transform: uppercase;
    color         : rgba($ColorStyles-ptext, .8);
    font-size     : $GlobalStyles-standard_font - 4px; 
}

.deposit_to_en {
    font-size     : $GlobalStyles-standard_font - 3px; 
    font-weight   : normal; 
    text-transform: none;
    margin-top    : $GlobalStyles-standard_space/2; 
}

.deposit_table {
    text-align   : center;
    margin-bottom: $GlobalStyles-standard_space*2;

    .bank_name {
        text-align: center;

        .bank_name_content {
            @include ColorStyles-slight();
            padding      : $GlobalStyles-standard_space/2;
            font-weight  : bold;
            margin-left  : -1px;
            margin-right : -1px; 
        }
    }

    .row {

        .row_title {
            font-weight: bold;
        }
    }
}

.row {
    @include ColorStyles-p();
    @include GlobalStyles-clearfix;
    text-align   : left;
    position     : relative;
    padding-left : 135px;

    + .row {
        border-top: 1px solid rgba($ColorStyles-plight,.5);
    } 

    &::before {
        @include ColorStyles-pdark(.2);
        @include GlobalStyles-pseudo(absolute);
        left  : 0px;
        top   : 0px;
        height: 100%;
        width : 135px;
    }

    .row_title {
        text-align : center;
        width      : 135px;
        color      : $ColorStyles-ptext;
        margin-left: -135px
    }

    .row_title, .row_content {
        position  : relative;
        display   : inline-block;
        float     : left;
        padding   : $GlobalStyles-standard_space/2 $GlobalStyles-standard_space;
        color     : $ColorStyles-ptext;
    }

    .row_content {
        width     : 100%;
        word-break: break-all;
    }
}

.deposit_proof_contact {
    text-align   : center;

    .row {

        .row_title {
        }

        .row_content {
            padding-top   : $GlobalStyles-standard_space;
            padding-bottom: $GlobalStyles-standard_space
        }
    }
}

.label_primary {
    font-weight: bold;
}

.label_secondary {
    color     : rgba($ColorStyles-ptext, .7); 
    margin-top: $GlobalStyles-standard_space/4;
    font-size : $GlobalStyles-standard_font - 2px; 
}

.home_button {
    padding: $GlobalStyles-standard_space * 2;

    :global(.Routes-home) {
        @include GlobalStyles-secondary_button;
        width    : 100%;
    }
}

.company_name {
    display: block;
}

@media all and (max-width: 429px) {

    .company_name {
        display: inline;
    }
}

@media all and (min-width: $GlobalStyles-screen-desktop) {

    .body_content_wrapper {
        @include GlobalStyles-floating_slight_card(true);
    }
}

@media all and (max-width: $GlobalStyles-screen-desktop - 1px) {
    // <withbackground>
    // .account_type {
    //  color: lighten($ColorStyles-secondary,60%);
    // }

    // .background_filler {
 //     @include ColorStyles-s;
 //     top: $GlobalStyles-standard_space*3;
    // }

    // .v_center_container {
    //  @include GlobalStyles-slant_container_top(0px);
    //  height: 100%;
    // }

    // .content_boundary {
    //  @include GlobalStyles-child-center('.body_content_wrapper');
    //  height : 100%;
    // }

    // .body_content_wrapper {
    //  padding: $GlobalStyles-standard_space*3 0px;
    // }

    // .row {
    //  @include ColorStyles-slight(.2);
    //  @include GlobalStyles-link_secondary_theme;

    //  + .row {
    //      margin-top: $GlobalStyles-standard_space;
    //  }

    //  :global(.Routes-realaccount) {
 //            @include GlobalStyles-form_secondary_theme_primary_button;
    //  }
    // }

    // <nobackground>
    .background_filler {
        @include ColorStyles-plight;
    }
    
    .body_content_wrapper {
        border: 1px solid darken($ColorStyles-plight, 10%);
    }
}